import React from 'react';

import Referral from '@/features/onboard/referral';

const ReferralPage = () => {
  return <Referral />;
};

export default ReferralPage;

export function Head() {
  return (
    <>
      <title>Super Champs HQ - Referral</title>
    </>
  );
}
